import { createVNode as _createVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body pt-9 pb-0" }
const _hoisted_3 = { class: "d-flex flex-wrap flex-sm-nowrap mb-3" }
const _hoisted_4 = { class: "me-7 mb-4" }
const _hoisted_5 = { class: "symbol symbol-100px symbol-lg-160px symbol-fixed position-relative" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = { class: "d-flex justify-content-between align-items-start flex-wrap mb-2" }
const _hoisted_8 = { class: "d-flex flex-column" }
const _hoisted_9 = { class: "d-flex align-items-center mb-2" }
const _hoisted_10 = { class: "text-gray-800 text-hover-primary fs-2 fw-bolder me-1" }
const _hoisted_11 = { class: "svg-icon svg-icon-1 svg-icon-primary" }
const _hoisted_12 = { class: "d-flex flex-wrap flex-stack" }
const _hoisted_13 = { class: "d-flex flex-column flex-grow-1 pe-8" }
const _hoisted_14 = { class: "d-flex flex-wrap" }
const _hoisted_15 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" }
const _hoisted_16 = { class: "d-flex align-items-center" }
const _hoisted_17 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_18 = {
  key: 0,
  class: "fs-2 fw-bolder",
  "data-kt-countup": "true",
  "data-kt-countup-value": "4500",
  "data-kt-countup-prefix": "$"
}
const _hoisted_19 = { class: "fw-bold fs-6 text-gray-400" }
const _hoisted_20 = { class: "border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3" }
const _hoisted_21 = { class: "d-flex align-items-center" }
const _hoisted_22 = { class: "svg-icon svg-icon-4 me-1" }
const _hoisted_23 = {
  class: "fs-2 fw-bolder",
  "data-kt-countup": "true",
  "data-kt-countup-value": "75"
}
const _hoisted_24 = { class: "fw-bold fs-6 text-gray-400" }
const _hoisted_25 = { class: "d-flex overflow-auto h-55px" }
const _hoisted_26 = { class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap" }
const _hoisted_27 = { class: "nav-item" }
const _hoisted_28 = { class: "nav-item" }
const _hoisted_29 = { class: "nav-item" }
const _hoisted_30 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")
  const _component_router_link = _resolveComponent("router-link")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _createVNode("img", {
                src: `media/avatars/${_ctx.user.uid}.jpg`,
                alt: "image"
              }, null, 8, ["src"])
            ])
          ]),
          _createVNode("div", _hoisted_6, [
            _createVNode("div", _hoisted_7, [
              _createVNode("div", _hoisted_8, [
                _createVNode("div", _hoisted_9, [
                  _createVNode("a", _hoisted_10, _toDisplayString(_ctx.user.email), 1),
                  _createVNode("a", null, [
                    _createVNode("span", _hoisted_11, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotone/Design/Verified.svg" })
                    ])
                  ])
                ])
              ])
            ]),
            _createVNode("div", _hoisted_12, [
              _createVNode("div", _hoisted_13, [
                _createVNode("div", _hoisted_14, [
                  _createVNode("div", _hoisted_15, [
                    _createVNode("div", _hoisted_16, [
                      _createVNode("span", _hoisted_17, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotone/General/Thunder-move.svg" })
                      ]),
                      (_ctx.totalDevices >= 0)
                        ? (_openBlock(), _createBlock("div", _hoisted_18, _toDisplayString(_ctx.totalDevices || 0), 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createVNode("div", _hoisted_19, _toDisplayString(_ctx.translate("devices")), 1)
                  ]),
                  _createVNode("div", _hoisted_20, [
                    _createVNode("div", _hoisted_21, [
                      _createVNode("span", _hoisted_22, [
                        _createVNode(_component_inline_svg, { src: "media/icons/duotone/General/User.svg" })
                      ]),
                      _createVNode("div", _hoisted_23, _toDisplayString(_ctx.totalUsers || 0), 1)
                    ]),
                    _createVNode("div", _hoisted_24, _toDisplayString(_ctx.translate("users")), 1)
                  ])
                ])
              ])
            ])
          ])
        ]),
        _createVNode("div", _hoisted_25, [
          _createVNode("ul", _hoisted_26, [
            _createVNode("li", _hoisted_27, [
              _createVNode(_component_router_link, {
                to: "/account/devices",
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("devices")), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode("li", _hoisted_28, [
              _createVNode(_component_router_link, {
                to: "/account/settings",
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("settings")), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode("li", _hoisted_29, [
              _createVNode(_component_router_link, {
                to: "/account/models",
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("models")), 1)
                ]),
                _: 1
              })
            ]),
            _createVNode("li", _hoisted_30, [
              _createVNode(_component_router_link, {
                to: "/account/clients",
                class: "nav-link text-active-primary me-6",
                "active-class": "active"
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.translate("clients")), 1)
                ]),
                _: 1
              })
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}